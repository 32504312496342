$(function () {
  // Scroll Hint
  new ScrollHint('.table-scroll-wrapper', {
    i18n: {
      scrollable: 'スクロールできます',
    },
  });

  // Sidebar
  $('.js-sidebar-trigger').on('click', function () {
    $(this).parent('.sidebar-list__item').toggleClass('active');
    $(this).next('.sidebar-second-list').stop().slideToggle(180);
  });
  if ($('.sidebar-list__link.current')) {
    var subMenuList = $('.sidebar-list__link.current').parents('.sidebar-second-list');
    subMenuList.slideDown(180);
    subMenuList.parents('.sidebar-list__item').addClass('active');
  }

  // Header
  var beforePos = 0; //スクロールの値の比較用の設定

  //スクロール途中でヘッダーが消え、上にスクロールすると復活する設定を関数にまとめる
  function ScrollAnime() {
    var elemTop = $('.construct-main').offset().top; //.construct-mainの位置まできたら
    var scroll = $(window).scrollTop();
    //ヘッダーの出し入れをする
    if (scroll == beforePos) {
      //IE11対策で処理を入れない
    } else if (elemTop > scroll || 0 > scroll - beforePos) {
      //ヘッダーが上から出現する
      $('.construct-header').removeClass('upMove'); //#headerにUpMoveというクラス名を除き
      $('.construct-header').addClass('downMove'); //#headerにDownMoveのクラス名を追加
      $('.sidebar-container').addClass('posDown');
    } else {
      //ヘッダーが上に消える
      $('.construct-header').removeClass('downMove'); //#headerにDownMoveというクラス名を除き
      $('.sidebar-container').removeClass('posDown');
      $('.construct-header').addClass('upMove'); //#headerにUpMoveのクラス名を追加
    }

    beforePos = scroll; //現在のスクロール値を比較用のbeforePosに格納
  }

  // 画面をスクロールをしたら動かしたい場合の記述
  $(window).scroll(function () {
    ScrollAnime(); //スクロール途中でヘッダーが消え、上にスクロールすると復活する関数を呼ぶ
  });

  // ページが読み込まれたらすぐに動かしたい場合の記述
  $(window).on('load', function () {
    ScrollAnime(); //スクロール途中でヘッダーが消え、上にスクロールすると復活する関数を呼ぶ
  });
});
